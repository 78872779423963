<template>
  <div class="d-flex align-center flex-wrap cond">
    <div
      :class="`d-flex align-center mb-4 pr-2 ${auditedValueColor}`"
      v-if="auditController.dataAudit && auditResult"
    >
      <audit-bool-icon
        class="mr-2"
        :value="computedAuditResult"
      />
      <span class="mr-2">{{$t('t.AuditedValue')}} :</span>
      <document-name-ref
        v-if="computedAuditValueType === 'document'"
        :id="computedAuditValue"
        :cache-type="$cacheTypeFromString(computedAuditValueCacheType)"
      />
      <span v-else-if="computedAuditValueType === 'bool'">{{$t(`t.${toPascalCase(computedAuditValue.toString())}`)}}</span>
      <calendar-date
        v-else-if="computedAuditValueType === 'date'"
        :date="computedAuditValue"
      />
      <span v-else-if="computedAuditValueType === 'number'">{{computedAuditValue}}</span>
      <span v-else>{{computedAuditStringValue}}</span>
    </div>
    <div class="d-flex align-center ml-1">
      <column-picker
        class="mr-4 mt-2"
        :selected-col-id.sync="computedCol"
        :selected-cur-id.sync="computedCurrency"
        :consolidated.sync="computedConsolidated"
        :filters="filters"
        @update:selected-document="updateColumn"
        ref="picker"
        :readonly="readonly"
        :rules="rules"
      />
      <v-select
        class="mr-4 operator mt-2"
        v-if="selectedColumn"
        v-model="computedOps"
        :items="selectedColumn.ops"
        item-text="text"
        item-value="id"
        :label="$t('t.Operators')"
      />
    </div>
    <component
      class="mt-2 comparand"
      :is="comparandValueType"
      v-if="selectedColumn && computedOps && dataValTy !== 'empty'"
      :document.sync="computedComparand"
      :target-value-type="selectedColumn.valTy"
      :is-between="isBetween"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import auditController from '@/auditController'

export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    AuditBoolIcon: () => import('@/components/audit-bool-icon'),
    Assigned: () => import('./comparand/assigned'),
    Bool: () => import('./comparand/bool'),
    ColumnPicker: () => import('@/components/column-picker'),
    Date: () => import('./comparand/date'),
    Document: () => import('./comparand/document'),
    Number: () => import('./comparand/number'),
    String: () => import('./comparand/string')
  },
  computed: {
    auditedValueColor () {
      return this.computedAuditResult === true ? 'audited-value-true' : 'audited-value-false'
    },
    computedAuditStringValue () {
      return this.computedAuditValue && this.computedAuditValue.length > 0 ? this.computedAuditValue : `'${this.$t('t.NoValue')}'`
    },
    computedAuditValue () {
      if (!this.auditResult) {
        return null
      }
      if (this.computedAuditValueType === 'document') {
        return this.auditResult[0].id
      } else {
        return this.auditResult[0]
      }
    },
    computedAuditValueCacheType () {
      if (this.computedAuditValueType === 'document') {
        return this.auditResult[0].type
      } else { return null }
    },
    computedAuditValueType () {
      return this.auditResult[1]
    },
    computedAuditResult () {
      return this.auditResult[2]
    },
    isBetween () {
      return this.computedOps === 'bw'
    },
    comparandValueType () {
      return this.getComparandValueType(this.dataValTy)
    },
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataOps = null
        this.dataComparand = null
        this.dataDocumentEmit()
      }
    },
    computedComparand: {
      get () { return this.dataComparand },
      set (v) {
        this.dataComparand = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedCurrency: {
      get () { return this.dataCurrency },
      set (v) {
        this.dataCurrency = v
        this.dataDocumentEmit()
      }
    },
    computedConsolidated: {
      get () { return this.dataConsolidated },
      set (v) {
        this.dataConsolidated = v
        this.dataDocumentEmit()
      }
    },
    computedOps: {
      get () { return this.dataOps },
      set (v) {
        if (this.dataOps === 'empty' || this.dataOps === 'bw') {
          this.dataComparand = null
        }

        this.dataOps = v

        if (v === 'empty') {
          this.dataValTy = 'empty'
        } else {
          this.dataValTy = this.getComparandValueType(Object.keys(this.selectedColumn?.valTy)[0])
        }
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    },
    rules () {
      return [v => !!v?.length || this.$t('t.IsRequired')]
    }
  },
  data () {
    return {
      auditController: auditController,
      dataComparand: null,
      dataCol: null,
      dataConsolidated: null,
      dataCurrency: null,
      dataOps: null,
      dataValTy: null,
      selectedColumn: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    componentName (type) {
      switch (type) {
        case 'document':
          return 'document-name-ref'
        default:
          console.trace(`cond missing componentName ${type}`)
          return null
      }
    },
    emitDocument () {
      const doc = {
        col: this.dataCol,
        ops: this.dataOps
      }

      if (this.dataOps !== 'empty') {
        this.$set(doc, 'comparand', { c: this.dataComparand || [], valTy: this.comparandValueType })
        this.$set(doc, 'currency', this.dataCurrency)
        this.$set(doc, 'consolidated', this.dataConsolidated)
      } else {
        this.$set(doc, 'comparand', { valTy: 'empty' })
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    updateColumn (v) {
      this.selectedColumn = this.lodash.cloneDeep(v)
      if (!this.computedOps) {
        this.computedOps = this.selectedColumn?.ops.some(_ => _.id === '=')
          ? '='
          : this.selectedColumn?.ops[0].id
      }
    },
    getComparandValueType (t) {
      if (['file', 'phone', 'email'].includes(t)) {
        t = 'string'
      }
      if (['amt', 'id-number', 'decimal', 'integer', 'percent'].includes(t)) {
        t = 'number'
      }
      if (['assigned', 'list'].includes(t)) {
        t = 'document'
      }
      if (['comment'].includes(t)) {
        t = 'empty'
      }
      return t
    }
  },
  watch: {
    document: {
      handler (v) {
        const cond = this.lodash.cloneDeep(v)
        this.dataCol = cond?.col
        this.dataComparand = cond?.comparand?.c
        this.dataCurrency = cond?.currency
        this.dataConsolidated = cond?.consolidated
        this.dataOps = cond?.ops
        this.dataValTy = cond?.comparand?.valTy
      },
      immediate: true
    }
  },
  props: {
    document: Object,
    targetDocTy: String,
    auditResult: Array,
    readonly: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.cond
  width 100%

.comparand
  max-height 200px
  overflow-y auto
  overflow-x hidden

.operator
  min-width 100px

.audited-value-false
  border-bottom 2px solid #f44336

.audited-value-true
  border-bottom 1px solid #4caf50
</style>
